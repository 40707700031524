.chronopost-icon {
  height: 100% !important;
  width: 100% !important;
  max-width: 100px;
  object-fit: scale-down;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.chronopost-icon {
  max-width: 200px;
}
