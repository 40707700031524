@import "styles/variables.scss";

.operation-group-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.operation-group {
  cursor: pointer;
  user-select: none !important;
  padding: 1rem;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid $darkGray;
  border-radius: 3px;

  p {
    font-size: 0.875rem;
    line-height: 1rem !important;
    margin-bottom: 0 !important;
  }

  &:not(:last-child) {
    // margin-right: 1rem;
  }
}

.is-selected {
  border: 2px solid $brandAccent !important;
  //   color: $brandSecondary;
  font-weight: bold;
  background-color: $shadeAccentTransparency;
  padding: calc(1rem - 1px);
}

.logo {
  width: 40px;
  margin-right: 0.5rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .logo {
    width: 25px;
  }
}
