@import "styles/variables.scss";

.incoming-shipping-container {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.title {
  padding: 1rem;
  font-size: clamp(1rem, 2.5vw, 2rem);
}

.incoming-shipping-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.shipping-service-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: $laptopBreakpoint) {
  .shipping-service-container,
  .incoming-shipping-bottom {
    padding: 0 1rem;
  }
  .title {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .shipping-service-container {
    gap: 0.5rem;
    padding: 0 0.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .incoming-shipping-bottom {
    padding: 0 0.5rem;
  }
  .title {
    padding: 0.5rem;
  }
}
