@import "styles/variables.scss";

.preview-list-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: white;
  padding: 0.5rem;
  border: 1px solid $darkGray;
}

.preview-list {
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow: auto;
}

.image-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-preview-left {
  display: flex;
  align-items: center;
}

.filename {
  font-size: 0.7rem;
}

.close-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}

.image-preview-miniature {
  filter: unset;
  height: 50px !important;
  object-fit: scale-down;
  padding: 3px;
  width: 50px !important;

  &:not(:last-child) {
    margin-right: 5px;
  }
  &:hover {
    border: 3px solid $brandAccent;
    padding: 0px;
  }
}
